import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/activities/sports_All.pdf','/assets/pdf/activities/sportresult_all.pdf','/assets/pdf/activities/intrasport1.pdf','/assets/pdf/activities/SPORTS NURSURIES.pdf'];
  src: string;
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }
  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.fourth').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.fourth').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      this.src = this.pdfSrc[3];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
  }