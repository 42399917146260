<div id="all-news" class="mt-3">
    <div class="container px-lg-5">
        <div class="row" *ngIf="news">
            <div class="col-12">
                <h2 class="text-center card-title">{{news.title}} ({{news.date}})</h2>
                <p class="mb-4">
                </p>
                <div class="row text-center mb-4" *ngIf="!(news.link)">
                    <div class="col-12">
                        <a href="{{news.link}}" data-lightbox="Notice"> <img [src]="news.imgUrl" alt="{{news.title}}" class="img-fluid rounded"></a> 
                    </div>
                </div>
                <p class="mb-4 inner-lead">
                    {{news.date}}
                </p>
                <a href="{{news.link}}" class="btn btn-primary text-white mb-4" target="_blank" download *ngIf="(news.link)">Watch</a>
            </div>
        </div>
        <div class="row" *ngIf="!news">
            <div class="col-12">
                <h2 class="text-center card-title">Notice</h2>
                <p class="my-4 inner-lead">There maybe no data for this!</p>
            </div>
        </div>
    </div>
</div>