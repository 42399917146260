import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  pdfSrcAll='/assets/pdf/academics/result_All.pdf';
  // pdfSrc10='/assets/pdf/academics/result10.pdf';
  // pdfSrc9='/assets/pdf/academics/result9.pdf';
  // pdfSrc8='/assets/pdf/academics/result8.pdf';
  // pdfSrc7='/assets/pdf/academics/result7.pdf';
  // pdfSrc6='/assets/pdf/academics/result6.pdf';
  // pdfSrc5='/assets/pdf/academics/result5.pdf';
  // pdfSrc4='/assets/pdf/academics/result4.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
