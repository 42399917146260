import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfectorial',
  templateUrl: './perfectorial.component.html',
  styleUrls: ['./perfectorial.component.scss']
})
export class PerfectorialComponent implements OnInit {
  pdfSrc='/assets/pdf/activities/PERFECTORIAL BOARD.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
