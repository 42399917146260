import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from 'src/app/view/about/about.component';
import { RouterModule } from '@angular/router';
import { MissionComponent } from 'src/app/view/about/mission/mission.component';
import { CommitteeComponent } from 'src/app/view/about/committee/committee.component';
import { FounderComponent } from 'src/app/view/about/founder/founder.component';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OverviewComponent } from 'src/app/view/about/overview/overview.component';



@NgModule({
  declarations: [
    AboutComponent,
    MissionComponent,
    CommitteeComponent,
    FounderComponent,
    OverviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'', component:AboutComponent, children:[
        {path:'mission', component:MissionComponent},
        {path:'founder', component:FounderComponent},
        {path:'committee', component:CommitteeComponent},
        {path:'overview', component:OverviewComponent},
      ]}
    ])
  ]
})
export class AboutModule { }
