import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-summercamps',
  templateUrl: './summercamps.component.html',
  styleUrls: ['./summercamps.component.scss']
})
export class SummercampsComponent implements OnInit {
  pdfSrcAll='/assets/pdf/activities/SummerCampAll.pdf';
  // pdfSrc='/assets/pdf/activities/summercamp2023.pdf';
  // pdfSrc1='/assets/pdf/activities/summercamp.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
