import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {
  activities:any[]=[
    // {
    //   title:"DEVANSHI AIR-JEE ADVANCE:7713 AIR-JEE MAINS QUALIFIED Pursuing B.tech (chemical Sciences from IIT ROORKEE",
    //   imgUrl:'/assets/img/achievements/1.PNG',
    //   link:'/assets/img/achievements/1.PNG'
    // },
    {
      name: 'AGASTYA SINGH',
      achievement: 'Game: Chess',
      achievement1:'Won Bronze Medal at State Level',
      achievement2: 'Selected for National Level',
      imgUrl: '/assets/img/achievements/agastya.jpg',
      link: '/assets/img/achievements/agastya.jpg',
    },
    {
      name: 'SANJEEDA',
      achievement: 'Game: Skating',
      achievement1:'Won 2 Silver Medal at State Level',
      achievement2: '& Bronze Medal in CBSE Cluster Games',
      imgUrl: '/assets/img/achievements/sanjeeda.jpg',
      link: '/assets/img/achievements/sanjeeda.jpg',
    },
    {
      name: 'JAISMEEN',
      achievement: 'Game: Shooting',
      achievement1:'Won Gold Medal at State Level',
      achievement2: '& Selected for National Level',
      imgUrl: '/assets/img/achievements/jaismeen.jpg',
      link: '/assets/img/achievements/jaismeen.jpg',
    },
    {
      name: 'AAKARSHIT',
      achievement: 'JEE EXAMINATION',
      achievement1:'PERCENTILE: 99.79',
      achievement2: 'Pursuing B.Tech from IIT Roorkee',
      imgUrl: '/assets/img/achievements/aakarshit.jpg',
      link: '/assets/img/achievements/aakarshit.jpg',
    },
    {
      name: 'RIDHI',
      achievement: 'JEE EXAMINATION',
      achievement1:'PERCENTILE: 98.61',
      achievement2: 'Pursuing B.Tech from IIT Patna',
      imgUrl: '/assets/img/achievements/ridhi.jpg',
      link: '/assets/img/achievements/ridhi.jpg',
    },
    {
      name: 'YASH JINDAL',
      achievement: 'JEE EXAMINATION',
      achievement1:'PERCENTILE: 97.88',
      achievement2: 'Pursuing B.Tech from IIT Patna',
      imgUrl: '/assets/img/achievements/yash.jpg',
      link: '/assets/img/achievements/yash.jpg',
    },
    {
      name: 'DEVANSHI',
      achievement: 'AIR-JEE ADVANCE: 7713',
      achievement1:'AIR-JEE MAINS QUALIFIED ',
      achievement2: 'Pursuing B.Tech (Chemical Sciences) from IIT ROORKEE',
      imgUrl: '/assets/img/achievements/1.PNG',
      link: '/assets/img/achievements/1.PNG',
    },
    {
      name: 'AVICHAL GOYAL',
      achievement: 'AIR-JEE ADVANCE QUALIFIED',
      achievement1: 'AIR-JEE MAINS: 5781',
      achievement2: 'Pursuing B.Tech (Comp. Sci, from NIT KURUKSHETRA)',
      imgUrl: '/assets/img/achievements/2.PNG',
      link: '/assets/img/achievements/2.PNG',
    },
    {
      name: 'AKSHIT GAUR',
      achievement: 'AIR-JEE ADVANCE QUALIFIED',
      achievement1: 'AIR-JEE MAINS QUALIFIED',
      achievement2: 'Pursuing B.Tech (Comp. Sci. from JNU,Delhi) UNIVERSITY',
      imgUrl: '/assets/img/achievements/3.PNG',
      link: '/assets/img/achievements/3.PNG',
    },
    {
      name: 'KESHAV VERMA',
      achievement: 'AIR-JEE ADVANCE QUALIFIED',
      achievement1: 'AIR-JEE MAINS: 8599',
      achievement2: 'Pursuing B.Tech (Comp. Sci. from NIT KURUKSHETRA)',
      imgUrl: '/assets/img/achievements/4.PNG',
      link: '/assets/img/achievements/4.PNG',
    },
    {
      name: 'ARYAN SAINI',
      achievement: 'AIR-JEE MAINS QUALIFIED',
      imgUrl: '/assets/img/achievements/5.PNG',
      link: '/assets/img/achievements/5.PNG',
    },
    {
      name: 'YUKTI',
      achievement: 'AIR-JEE MAINS QUALIFIED',
      imgUrl: '/assets/img/achievements/6.PNG',
      link: '/assets/img/achievements/6.PNG',
    },
    {
      name: 'ANKIT',
      achievement: 'AIR-JEE MAINS QUALIFIED',
      imgUrl: '/assets/img/achievements/7.PNG',
      link: '/assets/img/achievements/7.PNG',
    },
    {
      name: 'KESHAV SHARMA',
      achievement: 'AIR-JEE MAINS QUALIFIED',
      imgUrl: '/assets/img/achievements/8.PNG',
      link: '/assets/img/achievements/8.PNG',
    },
    {
      name: 'ANADITA',
      achievement: 'PURSUING B.COM Hons.',
      achievement1: 'From Delhi University',
      imgUrl: '/assets/img/achievements/9.PNG',
      link: '/assets/img/achievements/9.PNG',
    },
    {
      name: 'NANDINI SINGHAL',
      achievement: 'JEE MAINS (B.ARCH.)',
      achievement1: 'AIR: 193',
      imgUrl: '/assets/img/achievements/10.PNG',
      link: '/assets/img/achievements/10.PNG',
    },
    {
      name: 'DAKSH',
      achievement: 'NEET (AIR: 1661)',
      achievement1: 'AIIMS BATHINDA',
      imgUrl: '/assets/img/achievements/11.PNG',
      link: '/assets/img/achievements/11.PNG',
    },
    {
      name: 'AGASTYA XI D',
      achievement: 'Winner of State Level Chess Championship 2023',
      achievement1: 'Selected for National Level',
      imgUrl: '/assets/img/achievements/12.PNG',
      link: '/assets/img/achievements/12.PNG',
    },
    {
      name: 'JASMINE VII D',
      achievement: 'Winner of State Level Shooting Championship',
      achievement1: '(10 m Peep Sight Air Rifle) 2023',
      achievement2: 'Selected for National Level',
      imgUrl: '/assets/img/achievements/13.PNG',
      link: '/assets/img/achievements/13.PNG',
    },
    {
      name: 'CHANCHAL KAUSHIK VIII B',
      achievement: 'Honoured with the State Child Scientist Award 2023',
      achievement1: 'Selected for national level Competition',
      imgUrl: '/assets/img/achievements/14.PNG',
      link: '/assets/img/achievements/14.PNG',
    },
  ];
  isLoading=false;
  customOptions: OwlOptions = {
    margin:10,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  // constructor(private service:ActivitiesService) { }

  ngOnInit(): void {
    this.isLoading=true;
    // this.activities=this.service.getActivities();
    // this.service.getActivitiesList();
    // this.service.activitiesChange.subscribe(value=>{
      // this.activities=value;
      this.isLoading=false;
    // })
  }
}
