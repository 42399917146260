<div id="activities">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Awards & Scholarship</h4>
                    <div class="card-body text-justify">
                        <img src="/assets/img/activities/awards.png" alt="" class="img-fluid">
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>