import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultofcocurricular',
  templateUrl: './resultofcocurricular.component.html',
  styleUrls: ['./resultofcocurricular.component.scss']
})
export class ResultofcocurricularComponent implements OnInit {
  pdfSrc='';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
