import { Component, OnInit } from '@angular/core'; 
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lightGallery from 'lightgallery';
import { Subscription } from 'rxjs';
import { Gallery } from 'src/app/models/gallery.model';
import { ImageService } from 'src/app/services/image/image.service';
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  constructor(private imageService: ImageService) {
  }
  images: any[];
  coverImages: any[];
  filterImage: Gallery[] = [];
  sub: Subscription[] = [];
  ngOnInit(): void {

    this.imageService.getImageDetailList();
    this.sub[0] = this.imageService.imageDetailList.snapshotChanges().subscribe(
      list => {
        this.images = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        });
        this.imageService.setCategory(this.images);
        this.imageService.getCoverList().then(pos => {
          this.filterImage = this.imageService.setFilter(pos);
          this.filterImage = this.filterImage.reverse();
        });
      }
    );


  }
  openGalleryList(id: string, caption: string) {

    let image = this.imageService.getSingleTypeImages(caption);
    let newImage: { src: string, thumb: string, subHtml: string }[] = [];

    for (let i of image) {
      newImage.push({ src: i.imgUrl, thumb: i.imgUrl, subHtml: i.caption });
    }

    const $dynamicGallery = document.getElementById(id);
    const dynamicGallery = lightGallery($dynamicGallery, {
      dynamic: true,
      plugins: [lgZoom, lgShare, lgThumbnail],
      dynamicEl: newImage,
    });


    $('.lg-backdrop').css('z-index', 99999);
    $('.lg-outer').css('z-index', 99999);
    // Starts with third item.(Optional).
    // This is useful if you want use dynamic mode with
    // custom thumbnails (thumbnails outside gallery),
    dynamicGallery.openGallery(0);





  }

  ngOnDestroy() {
    this.sub.forEach(s => s.unsubscribe())
  }
}
