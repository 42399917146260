import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docinfo',
  templateUrl: './docinfo.component.html',
  styleUrls: ['./docinfo.component.scss']
})
export class DocinfoComponent implements OnInit {
  pdfSrc='/assets/pdf/DOCUMENTSANDINFORMATION.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
