<app-banner></app-banner>
<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>Fee Payment</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/fees/student"><i class="fa-solid fa-angle-right"></i> Student App</a>
                    </li>
                    <li routerLinkActive="active">
                        <a href="https://app.okiedokiepay.com/#!/65e2d283e933a034b13fe692/entityLandingPage" target="_blank"><i class="fa-solid fa-angle-right"></i> Click To Pay Fees</a>                        
                    </li>                    
                </ul>
            </nav>
        </div>

        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>