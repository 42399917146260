import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.scss']
})
export class AlumniComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  onCloseModal() {
    const video = this.videoPlayer.nativeElement;
    if (!video.paused && !video.ended) {
      video.pause();
    }
  }
}
