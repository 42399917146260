<div id="home-activities" class="py-5">
    <div class="container px-2 px-lg-5" data-scroll>
        <div class="row">
            <div class="col-12">
                <h2 class="text-center">Recent Activities</h2>

            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <!-- <app-loader></app-loader> -->
        </div>
        <div *ngIf="!isLoading">
            <owl-carousel-o [options]="customOptions">

                <ng-container *ngFor="let event of activities; let i=index">
                    <ng-template carouselSlide>
                        <div class="documentation mt-4">
                            <div class="card shadow-lg mx-auto">
                                <a href="{{event.imgUrl}}" data-lightbox="Activities" attr.data-title="{{event.title}}">
                                    <img appLazyload class="card-img-top" [src]="event.imgUrl" [alt]="event.title">
                                </a>
                                <div class="card-body">
                                    <a href="{{event.link}}" target="_blank">{{event.title}} <i
                                            class="fas fa-arrow-right"></i></a>
                                </div>

                            </div>
                        </div>



                    </ng-template>
                </ng-container>
            </owl-carousel-o>

        </div>
        <div class="row mt-5">
            <div class="col-12 text-center">
                <button class="btn" routerLink="/gallery">VIEW ALL</button>
            </div>
        </div>
    </div>
</div>