<app-banner></app-banner>
<div id="alumni" class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg inner-card">
                <h4 class="card-title text-center">Alumni</h4>
                <div class="card-body text-justify">
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <video #videoPlayer controls class="w-100 rounded-4 shadow">
                                <source src="/assets/img/alumni/alumni.mp4" type="video/mp4">
                            </video>
                        </div>
                        <div class="col-12 col-md-7 my-auto">
                            <p>Alumni are an effective role model and valuable resource of any institute.</p>
                            <p>Aggarsain Public School fairly provides opportunities to the Alumni to visit & share the gleaming times spent here in the school with the whole APS family.</p>
                            <p>We solemnly request you to join us, revive and share your old memories and experience with us, our budding learners and encourage them to emulate certain skills and values in future to keep alive the legacy of excellence.</p>
                        </div>
                    </div>
                </div>
                <div class="card-body text-justify" style="overflow: hidden;">
                    <!-- Embedding the iframe -->
                    <iframe aria-label='REGISTRATION FOR ALUMNI ASSOCIATION' frameborder="0" style= "width: 100%; height: 1700px; border: none;" src='https://data.odpay.in/odforms/form/RegistrationForAlumniAssociation1/formperma/_PCjUFAP4zoVvjeDhEiK_mVQ6L6X-MKV2xukgw954vE'></iframe>
                </div>
            </div>
        </div>
    </div>
    <div>
        <router-outlet></router-outlet>
    </div>
</div>
