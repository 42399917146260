<div id="about-us" class="mt-4">
    <div class="container">
        <div class="row">
            <div class="col-12 ">
                <div class="card news float-end ms-md-4 mb-2" data-scroll>
                    <div class="card-header">
                        NOTICE BOARD
                    </div>
                    <ul class="list-group list-group-flush">
                        <marquee behavior="scroll" direction="up" onmouseover="this.stop();" onmouseout="this.start();">
                            <li class="list-group-item"
                                (click)="redirect('https://data.odpay.in/odforms1/form/AggarsainPublicSchoolAdmissionQuery/formperma/NzFdErrx3t8WMinJx43Lkk2kBZox7r9W6C7GwBSmSdg')">
                                <div class="row">
                                    <div class="col-5 col-lg-4">
                                        <div class="box d-flex justify-content-center align-items-center">
                                            <p class="paraBox mb-0 py-4">07 December 2024</p>
                                        </div>
                                    </div>
                                    <div class="col-7 col-lg-8">
                                        <a>Registration Open 2025-26</a> <br>
                                        <small></small>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item" *ngFor="let item of notices;"
                                [routerLink]="['/notices',item.$key]">
                                <div class="row">
                                    <div class="col-5 col-lg-4">
                                        <div class="box d-flex justify-content-center align-items-center">
                                            <p class="paraBox mb-0 py-4">{{item.date}}</p>
                                        </div>
                                    </div>
                                    <div class="col-7 col-lg-8">
                                        <!-- <a>{{item.title}} <img src="/assets/img/about-us/click.webp" alt="Click Here"
                                                class="img-fluid" style="height: 50px;"></a> <br> -->
                                        <a>{{item.title}}</a> <br>
                                        <small>{{item.desc.length >100 ? (item.desc | slice:0:100) + '...'
                                            : item.desc }}</small>
                                    </div>
                                </div>


                            </li>
                        </marquee>
                    </ul>
                </div>
                <h2 class="h3  pb-3 ">Welcome To Aggarsain Public School</h2>
                <p class="mb-1">Aggarsain Public School, Kurukshetra, was established in April 1981 under the aegis of
                    the Maharaja Aggarsain Public School Society (regd.) founded by 21 philanthropists from Kurukshetra.
                    The vision behind its establishment was to provide quality education to the children of this holy
                    city. Since its inception, the school has experienced remarkable growth.
                </p>
                <p class="mb-1">In 1987, the institution moved to its own building, a campus sprawling over 7 acres of
                    land situated in the heart of the city . Today, the school boasts a multi-storeyed campus with 105
                    well-furnished air- conditioned classrooms, an auditorium with a seating capacity of approximately
                    2,000, a well-equipped library, audio-visual rooms, modern laboratories and extensive playgrounds.
                </p>
                <p class="mb-1">The school was affiliated with the CBSE, New Delhi, in 1989. Currently, it serves over
                    2,600 students, making it the largest school in the region.</p>
                <h2 class="h3  pb-1 mt-4">Vision</h2>
                <p class="mt-4 mb-1">To nurture globally aware, compassionate and innovative individuals by fostering a
                    dynamic environment of learning, creativity and inclusivity. We strive to build an exceptional
                    system where every child’s potential is realized and their character is as valued as
                    academic excellence.</p>
                <h2 class="h3  pb-1 mt-4">Mission</h2>
                <p class="mt-4 mb-1">With a motto of 'Wake to Love and Light', we strive to nurture confident,
                    self-directed learners equipped with skills, knowledge and wisdom. Through a holistic education, we
                    ignite a passion for lifelong learning and instill a commitment to serve society with
                    zeal and purpose.</p>
            </div>
        </div>


    </div>
</div>