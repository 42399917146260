import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eco-club',
  templateUrl: './eco-club.component.html',
  styleUrls: ['./eco-club.component.scss']
})
export class EcoClubComponent implements OnInit {
  pdfSrc='/assets/pdf/activities/ECO CONSCIOUS APSIANS.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
