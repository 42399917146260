import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schoolband',
  templateUrl: './schoolband.component.html',
  styleUrls: ['./schoolband.component.scss']
})
export class SchoolbandComponent implements OnInit {
  pdfSrc='/assets/pdf/activities/ourschoolband.pdf'
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
