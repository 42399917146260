import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-annualday',
  templateUrl: './annualday.component.html',
  styleUrls: ['./annualday.component.scss']
})
export class AnnualdayComponent implements OnInit {
  pdfSrc: string[] = ['/assets/pdf/activities/JUNIOR ANNUAL FUNCTION 2023 24.pdf',
  '/assets/pdf/activities/SENIOR WING ANNUAL FUNCTION.pdf',
  ];
  imgSrc: string[] = ['assets/img/activities/vibgyor_2023.png','assets/img/activities/praveg_2023.png'];
  src: string;
  isrc: string;
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      this.src = this.pdfSrc[0];
      this.isrc = this.imgSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      
      this.src = this.pdfSrc[1];
      this.isrc = this.imgSrc[1];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
  }
  