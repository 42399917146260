<div id="feepayment">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Student App</h4>
                    <div class="card-body text-justify">
                        <p class="lead inner-lead text-justify">Aggarsain Public School recognizes the cruciality of
                            parents-school relationship to a child's overall development. In order to continuously
                            communicate with parents and keep them informed about their child's accomplishments,
                            advancement, and future steps, our school is driven by a student mobile app.</p>
                        <p class="lead inner-lead text-justify">Here at Aggarsain Public School, we firmly think that
                            the best way to achieve education's main goal—ensuring students' holistic development and
                            assisting them in realizing their potential—is by making the most of technology. Based on
                            the same principles, the Parent Portal and Mobile App at Aggarsain Public School provide
                            features that help keep parents on top of technological advancements through frequent
                            updates and innovation.</p>
                        <p class="lead inner-lead text-justify fw-bold">How Can you install and operate mobile app?</p>
                        <p class="lead inner-lead text-justify fw-bold">For Mobile App:</p>
                        <ul>
                            <li>
                                <p class="lead inner-lead text-justify">Go to Google Play Store / Apple Store and search
                                    Student Portal by Okie Dokie</p>
                            </li>
                            <div class="d-flex justify-content-center">
                                <div class="col-lg-5"><a href="https://play.google.com/store/apps/details?id=com.okiedokiepay&pcampaignid=web_share" target="_blank"><img src="/assets/img/logo/play.png" alt="" class="img-fluid rounded-2"></a></div>
                                <div class="col-lg-5"><a href="https://apps.apple.com/in/app/students-portal-by-okie-dokie/id1670985936" target="_blank"><img src="/assets/img/logo/app.png" alt="" class="img-fluid rounded-2"></a></div>
                            </div>
                            <li>
                                <p class="lead inner-lead text-justify">Install and open the mobile app.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Log In with your registered Mobile Number with
                                    school.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Select your Profile.</p>
                            </li>
                        </ul>
                        <p class="lead inner-lead text-justify fw-bold">How to make Online Payments through Our Mobile App?</p>
                        <ul>
                            <li>
                                <p class="lead inner-lead text-justify">Open Student Portal by Okie Dokie.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Enter your Registered Mobile Number then click
                                    on Get OTP.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Fill the OTP and then click on Sign In button.
                                </p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Select your profile.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Go to the 4 Dots given on the centre Bottom of
                                    the page.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Click on Pay Fees Icon given on top below the
                                    School Name.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Select the Instalments for which you want to pay
                                    and click on Go button.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Click On View Details to see the Head wise
                                    Detail of Fee Amount.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Click on Pay Button.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Select the Payment mode (Credit Card, UPI, Debit
                                    Card) & Click on Proceed to Pay.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Fill in the required details to complete the
                                    transaction.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">Click On Pay.</p>
                            </li>
                            <li>
                                <p class="lead inner-lead text-justify">After Successful Payment, a Fee Receipt will be
                                    generated.</p>
                            </li>
                        </ul>

                        <p class="lead inner-lead text-justify fw-bold">Explore Salient features of the Mobile App:</p>
                        <p class="lead inner-lead text-justify"><b>My Profile –</b> You can view the personal details of
                            the student and parents along with the address updated by the school.</p>
                        <p class="lead inner-lead text-justify"><b>Fee Details –</b> The status of fees—due, pending,
                            and paid—can be viewed. You can also view fee receipts through the mobile app as well.</p>
                        <p class="lead inner-lead text-justify"><b>Transport -</b> You may track the location of the
                            vehicle. Also, you may check the timing for Pickup and Drop of your ward.</p>
                        <p class="lead inner-lead text-justify"><b>Attendance –</b> For one month or the full academic
                            year, you can look up your ward's attendance.</p>
                        <p class="lead inner-lead text-justify"><b>Circular/Notice –</b> You may view all the circulars
                            updated by the school authorities.</p>
                        <p class="lead inner-lead text-justify"><b>Homework/Assignments –</b> You can check the
                            Homework/Assignment uploaded by Class Teachers and submit the same after completing it
                            within time.</p>
                        <p class="lead inner-lead text-justify"><b>School Diary –</b> You can view the Homework and Date
                            Sheet of your ward.</p>
                        <p class="lead inner-lead text-justify"><b>Syllabus –</b> You may download the syllabus of the
                            class in which your ward is studying.</p>
                        <p class="lead inner-lead text-justify"><b>E-Learning –</b> You can access the lecture videos
                            anytime uploaded by the Class Teacher.</p>
                        <p class="lead inner-lead text-justify"><b>Library –</b> You can view the record of books issued
                            to your ward in the school. Also, you can check the availability of books in School Library.
                        </p>
                        <p class="lead inner-lead text-justify"><b>Apply Leaves –</b> You may apply leave(s) for your
                            ward.</p>
                        <p class="lead inner-lead text-justify"><b>Performance –</b> You can see subject-wise marks and
                            grades and the overall performance of your ward throughout the year.</p>
                        <p class="lead inner-lead text-justify"><b>Exams –</b> You can download the progress report of
                            your ward.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>