import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-s-twothree',
  templateUrl: './s-twothree.component.html',
  styleUrls: ['./s-twothree.component.scss']
})
export class STwothreeComponent implements OnInit {
  pdfSrc: string[] = [
  ];
  src: string;
  isLoading = true;
  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = '/assets/pdf/secondary/Glimpses of the month of April 2023.pdf';
    }
    if (year == '5') {
      $('.five').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = '/assets/pdf/secondary/Glimpses of the month of May 2023.pdf';
    }
    if (year == '6') {
      $('.six').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.four').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = '/assets/pdf/secondary/REPORT OF CCA ACTIVITIES HELD IN JUNE 2023.pdf';
    }
    if (year == '7') {
      $('.seven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.four').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[6];
    }
    if (year == '8') {
      $('.eight').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.four').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[7];
    }
    if (year == '9') {
      $('.nine').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.four').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[8];
    }
    if (year == '10') {
      $('.ten').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.four').removeClass('active');
      $('.eleven').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[9];
    }
    if (year == '11') {
      $('.eleven').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.four').removeClass('active');
      $('.twelve').removeClass('active');
      this.src = this.pdfSrc[10];
    }
    if (year == '12') {
      $('.twelve').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      $('.five').removeClass('active');
      $('.six').removeClass('active');
      $('.seven').removeClass('active');
      $('.eight').removeClass('active');
      $('.nine').removeClass('active');
      $('.ten').removeClass('active');
      $('.eleven').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[11];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
