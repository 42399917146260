import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ncc',
  templateUrl: './ncc.component.html',
  styleUrls: ['./ncc.component.scss']
})
export class NccComponent implements OnInit {
  
  pdfSrcAll='/assets/pdf/activities/nccAll.pdf';
  // pdfSrc6='/assets/pdf/activities/ncc6.pdf';
  // pdfSrc5='/assets/pdf/activities/ncc5.pdf';
  // pdfSrc4='/assets/pdf/activities/ncc4.pdf';
  // pdfSrc3='/assets/pdf/activities/ncc3.pdf';
  // pdfSrc2='/assets/pdf/activities/ncc2.pdf';
  // pdfSrc1='/assets/pdf/activities/ncc1.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
