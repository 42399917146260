import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit {
  facility: any[] = [
    {
      title: 'Library',
      desc: 'Library is a hub of learning, creativity and exploration, designed to inspire students to discover the joy of reading and develop a lifelong love for books. Our well-stocked library boasts a diverse collection of books, magazines and digital resources catering to students of all age groups and interests. From academic references to fiction, non-fiction and encyclopedias, our library serves as a treasure trove of information and inspiration.',
      imgUrl: '/assets/img/facility/library.JPG',
      showMore: false
    },
    {
      title: 'Transport',
      desc: 'Our transport facility ensures the utmost safety and convenience for students. We have a fleet of 20 school vans, each equipped with CCTV surveillance and GPS monitoring to ensure real-time tracking. Every van is accompanied by a well-trained lady attendant and a conductor, prioritizing the care and security of the children. The vans operate on selected routes, covering almost all directions in the city and neighboring areas, ensuring reliable and efficient transportation for our students.',
      imgUrl: '/assets/img/facility/SCHOOL VANS.png',
      showMore: false
    },
    {
      title: 'Campus',
      desc: 'Designed to foster a vibrant and conducive learning environment, the campus reflects the school’s commitment to providing holistic education and world-class facilities. The multi-storeyed campus is home to 105 well-furnished air-conditioned classrooms, ensuring a comfortable learning atmosphere for students. The school also features a state-of-the-art auditorium with a seating capacity of approximately 2,000, which serves as a venue for various cultural events, seminars and workshops. Our well-equipped library is a treasure trove of knowledge, offering a vast collection of books, magazines, and digital resources to enhance students\' learning experiences. In addition, the campus includes modern laboratories for Physics, Chemistry, Biology, and Computer Science, enabling hands-on exploration and practical learning. For sports and physical activities, the campus boasts extensive playgrounds, encouraging students to develop teamwork, discipline, and a healthy lifestyle. The campus continues to evolve, embodying the school’s vision of delivering quality education. With over 2,600 students, APS stands as the largest school in the region and a beacon of academic and extracurricular excellence. Our campus is not just a space for learning but a nurturing ground for the leaders of tomorrow.',
      imgUrl: '/assets/img/showcase/1.jpg',
      showMore: false
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
