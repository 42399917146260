import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {
  link: string = '';
  id:string;
  sub:Subscription[]=[];
  news:any;
  constructor(private newsService:NewsService,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    // this.route.data.subscribe(response=>{
    //   console.log(response);
    // })
    this.link='https://aps-frontend.web.app' + location.pathname;
    this.sub[0]=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        this.newsService.searchNews(this.id);
      }
    );
    this.sub[1] = this.newsService.newsItemChnage.subscribe(news=>{
      this.news = news;
    });
  }
}
