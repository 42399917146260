import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debadecl',
  templateUrl: './debadecl.component.html',
  styleUrls: ['./debadecl.component.scss']
})
export class DebadeclComponent implements OnInit {
  pdfSrcAll='/assets/pdf/activities/DebateAll.pdf';
  pdfSrc='/assets/pdf/activities/debate.pdf';
  pdfSrc2='/assets/pdf/activities/debate2.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
