import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { PublicationService } from "../services/publication/publication.service";

@Injectable({
    providedIn:'root'
})
export class PublicationResolverService implements Resolve<any[]>{
    constructor(private publicationService:PublicationService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any[] | Observable<any[]> | Promise<any[]> {
        const publication = this.publicationService.getPublication();
        if(publication.length === 0){
            this.splitUrl();
            
        }else{
            return publication;
        }
    }
    splitUrl(){
        let url=location.pathname.split('/');
        if(url.length===3){
            this.publicationService.getPublicationSearchList(url[2]);
        }
        
      }
}