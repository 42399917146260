import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllNoticeComponent } from 'src/app/view/all-notice/all-notice.component';
import { NoticeItemComponent } from 'src/app/view/all-notice/notice-item/notice-item.component';
import { RouterModule } from '@angular/router';
import { NoticeResolverService } from 'src/app/resolver/notice-resolver.service';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AllNoticeComponent,
    NoticeItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:AllNoticeComponent,children:[
        {path:':id',component:NoticeItemComponent, resolve:[NoticeResolverService]}
      ]}
    ])
  ]
})
export class AllNoticeModule { }
