import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NoticeService } from 'src/app/services/notice/notice.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  // notice:{title:string,description:string,date:string}[]=[
  //     {
  //       title:'Testing',
  //       description:'Testing',
  //       date:'14-March-2024'
  //     },
  //   ]

  hardcodedNotice = {
    id: 'hardcoded-1',
    title: 'Registration Open 2025-26',
    description: '',
    date: '07 Decemeber 2024',
    link: 'https://data.odpay.in/odforms1/form/AggarsainPublicSchoolAdmissionQuery/formperma/NzFdErrx3t8WMinJx43Lkk2kBZox7r9W6C7GwBSmSdg'
  };

  notices: any[] = [];
  sub: Subscription[] = [];
  constructor(private service: NoticeService) { }


  ngOnInit() {
    this.service.getNoticeList();
    this.sub[0] = this.service.noticeChange.subscribe(
      notice => {
        // this.notices = [this.hardcodedNotice, ...notice];
        this.notices = notice;
      }
    );
  }
  redirect(url: string) {
    window.open(url, '_blank');
  }
  ngOnDestroy() {
    this.sub.forEach(s => s.unsubscribe());
  }

}
