import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllPublicationComponent } from 'src/app/view/all-publication/all-publication.component';
import { PublicationItemComponent } from 'src/app/view/all-publication/publication-item/publication-item.component';
import { RouterModule } from '@angular/router';
import { PublicationResolverService } from 'src/app/resolver/publication-resolver.service';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    AllPublicationComponent,
    PublicationItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:AllPublicationComponent,children:[
        {path:':id',component:PublicationItemComponent, resolve:[PublicationResolverService]}
      ]}
    ])
  ]
})
export class AllPublicationModule { }
