import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacanciesComponent } from 'src/app/view/vacancies/vacancies.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ApplyComponent } from 'src/app/view/vacancies/apply/apply.component';



@NgModule({
  declarations: [
    VacanciesComponent,
    ApplyComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:VacanciesComponent, children:[
        {path:'vacancies',component:VacanciesComponent},
        {path:'apply',component:ApplyComponent} 
      ]}])
    ]
  }
)
export class VacanciesModule { }
