<div id="facility" class=" bg-light">
    <div class="container" data-scroll>
        <div class="row">
            <div class="col-12">
                <h2 class="text-center">Facilities</h2>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4 mb-5" *ngFor="let data of facility; let i=index">
                <div class="card shadow">
                    <div class="image">
                        <img class="card-img-top h-100" [src]="data.imgUrl" alt="Card image cap img-fluid">
                    </div>
                    <div class="card-body p-0">
                        <div class="inner">
                            <div class="circle1"></div>
                            <h2 class="card-title"><a href="">{{ data.title }}</a></h2>
                            <!-- <p class="card-text m-0">{{ data.desc }}</p> -->
                            <p class="card-text m-0" *ngIf="!data.showMore">{{ data.desc | slice:0:200 }}...</p>
                            <p class="card-text m-0" *ngIf="data.showMore">{{ data.desc }}</p>
                            <a (click)="data.showMore = !data.showMore">
                                {{ data.showMore ? 'Show Less' : 'Show More' }}
                            </a>
                        </div>
                        <!-- <a routerLink="/" class="button w-100">Know More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>