import { Component, OnDestroy, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  popups:string[] = [
    '/assets/img/home-admission/1.jpg',
    '/assets/img/home-admission/2.jpg'
  ]
  constructor() { }

  ngOnInit(): void {
    $("#myModal").modal('show');
  }

  ngAfterViewInit(): void {
    $('#popupCarousel').carousel({
      interval: 3000, //set the initial interval
      pause: false
    });
  }

  ngOnDestroy(){
    $("#myModal").modal('hide');
  }
}
