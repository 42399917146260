import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  activities:any[]=[
    {
      title:'WINTER FEST 2024',
      imgUrl:'/assets/img/home/recent/winter.jpg',
      link:'/assets/img/home/recent/winter.jpg'
    },
    {
      title:'26 JANUARY 2024',
      imgUrl:'/assets/img/home/recent/jan.JPG',
      link:'/assets/img/home/recent/jan.JPG'
    },
    {
      title:'TRIPS & EXCURSIONS',
      imgUrl:'/assets/img/home/recent/trip.jpg',
      link:'/assets/img/home/recent/trip.jpg'
    },
    {
      title:'ANNUAL FUNCTION PRAVEG 2023',
      imgUrl:'/assets/img/home/recent/annual.JPG',
      link:'/assets/img/home/recent/annual.JPG'
    },
  ];
  isLoading=false;
  customOptions: OwlOptions = {
    margin:10,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  
  }
  // constructor(private service:ActivitiesService) { }

  ngOnInit(): void {
    this.isLoading=true;
    // this.activities=this.service.getActivities();
    // this.service.getActivitiesList();
    // this.service.activitiesChange.subscribe(value=>{
      // this.activities=value;
      this.isLoading=false;
    // })
  }
}
