import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slc',
  templateUrl: './slc.component.html',
  styleUrls: ['./slc.component.scss']
})
export class SlcComponent implements OnInit {
  pdfSrcAll='/assets/pdf/academics/slcAll.pdf';
  // pdfSrc2='/assets/pdf/academics/slc2.pdf';
  // pdfSrc='/assets/pdf/academics/slc.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
