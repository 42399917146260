import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geninfo',
  templateUrl: './geninfo.component.html',
  styleUrls: ['./geninfo.component.scss']
})
export class GeninfoComponent implements OnInit {
  pdfSrc='/assets/pdf/geninfo.pdf'
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
