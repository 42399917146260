import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllNewsComponent } from 'src/app/view/all-news/all-news.component';
import { NewsItemComponent } from 'src/app/view/all-news/news-item/news-item.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    AllNewsComponent,
    NewsItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:AllNewsComponent,children:[
        {path:':id',component:NewsItemComponent}
      ]}
    ])
  ]
})
export class AllNewsModule { }
