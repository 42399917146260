import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-twelve',
  templateUrl: './twelve.component.html',
  styleUrls: ['./twelve.component.scss']
})
export class TwelveComponent implements OnInit {
  id:string;
  constructor( private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.id = params.id;
       if(this.id ==='year23'){
          $(`#${this.id}`).addClass('show');
          $('#y2023').prop('checked',true);
        }else if(this.id ==='year22'){
          $(`#${this.id}`).addClass('show');
          $('#y2022').prop('checked',true);
        }else if(this.id ==='year21'){
          $(`#${this.id}`).addClass('show');
          $('#y2021').prop('checked',true);
        }else if(this.id ==='year18'){
          $(`#${this.id}`).addClass('show');
          $('#y2018').prop('checked',true);
        }else if(this.id ==='year16'){
          $(`#${this.id}`).addClass('show');
          $('#y2016').prop('checked',true);
        }
      }
    );
  }

  setPage(id){
    console.log(id)
    if(id==='2023-24'){
      $(`#year23`).addClass('show');
      $(`#year22`).removeClass('show');
      $(`#year21`).removeClass('show');
      $(`#year18`).removeClass('show');
      $(`#year16`).removeClass('show');
    }
    if(id==='2022-23'){
      $(`#year22`).addClass('show');
      $(`#year23`).removeClass('show');
      $(`#year21`).removeClass('show');
      $(`#year18`).removeClass('show');
      $(`#year16`).removeClass('show');
    }
    if(id==='2021-22'){
      $(`#year21`).addClass('show');
      $(`#year22`).removeClass('show');
      $(`#year23`).removeClass('show');
      $(`#year18`).removeClass('show');
      $(`#year16`).removeClass('show');
    }
    if(id==='2018-19'){
      $(`#year18`).addClass('show');
      $(`#year21`).removeClass('show');
      $(`#year22`).removeClass('show');
      $(`#year23`).removeClass('show');
      $(`#year16`).removeClass('show');
    }
    if(id==='2016-17'){
      $(`#year16`).addClass('show');
      $(`#year18`).removeClass('show');
      $(`#year21`).removeClass('show');
      $(`#year22`).removeClass('show');
      $(`#year23`).removeClass('show');
    }
  }
}
