<div id="vacancies" class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg inner-card">
                <h4 class="card-title text-center">Vacancies</h4>
                <div class="card-body text-justify">
                    <div class="pdf-container text-center">
                        <!-- <pdf-viewer [src]="pdfSrc"
                            [render-text]="true"
                            [original-size]="false"
                            style="display: block; width: 50%; height: 50%; margin: 0 auto;">
                        </pdf-viewer> -->
                        <a href="/assets/img/vacancies/1.jpg" data-lightbox="Result" attr.data-title="1">
                            <img appLazyload class="img-fluid" src="/assets/img/vacancies/1.jpg" alt="1">
                        </a>
                    </div>
                    <a class="btn mt-3" href="https://data.odpay.in/odforms/form/AGGARSAINPUBLICSCHOOL/formperma/t-fxqyel-Hi-A53x33Tg-PQKFH2C6mRhz4aC6IIZPzU?zf_enablecamera=true" target="_blank">Click Here To Apply</a>
                </div>
            </div>
        </div>
    </div>
    <div>
        <router-outlet></router-outlet>
    </div>
</div>