<!-- <app-banner></app-banner> -->
<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>Admission</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/admission/admissions"><i class="fa-solid fa-angle-right"></i> ADMISSION OPEN 2025-26</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/admission/feestructure"><i class="fa-solid fa-angle-right"></i> Fee Structure</a>
                    </li>                    
                    <li routerLinkActive="active">
                        <a routerLink="/admission/uniform"><i class="fa-solid fa-angle-right"></i> School Uniform</a>
                    </li>                    
                    <li routerLinkActive="active">
                        <a routerLink="/admission/entrance"><i class="fa-solid fa-angle-right"></i> Entrance Test Syllabus</a>
                    </li>                    
                </ul>
            </nav>
        </div>

        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>