import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacultyComponent } from 'src/app/view/faculty/faculty.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';




@NgModule({
  declarations: [
    FacultyComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:FacultyComponent, children:[
        {path:'faculty',component:FacultyComponent}
      ]}])
  ]
})
export class FacultyModule { }
