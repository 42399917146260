<div id="cbseres">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Class X Results</h4>
                    <div class="card-body text-center">

                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="btnradio" id="y2023" autocomplete="off">
                            <label class="btn btn-outline-danger" data-bs-toggle="collapse" data-bs-target="#year23"
                                (click)="setPage('2023-24')" for="y2023">2023-24</label>

                            <input type="radio" class="btn-check" name="btnradio" id="y2022" autocomplete="off">
                            <label class="btn btn-outline-danger" data-bs-toggle="collapse" data-bs-target="#year22"
                                (click)="setPage('2022-23')" for="y2022">2022-23</label>

                            <input type="radio" class="btn-check" name="btnradio" id="y2021" autocomplete="off">
                            <label class="btn btn-outline-danger" data-bs-toggle="collapse" data-bs-target="#year21"
                                (click)="setPage('2021-22')" for="y2021">2021-22</label>
                            <input type="radio" class="btn-check" name="btnradio" id="y2018" autocomplete="off">
                            <label class="btn btn-outline-danger" data-bs-toggle="collapse" data-bs-target="#year18"
                                (click)="setPage('2018-19')" for="y2018">2018-19</label>
                            <input type="radio" class="btn-check" name="btnradio" id="y2016" autocomplete="off">
                            <label class="btn btn-outline-danger" data-bs-toggle="collapse" data-bs-target="#year16"
                                (click)="setPage('2016-17')" for="y2016">2016-17</label>
                        </div>

                        <div class="collapse mt-3" id="year23">
                            <div class="row">
                                <a href="/assets/img/result/X (2023-24).jpg" data-lightbox="Result"
                                    attr.data-title="X 2023-24">
                                    <img appLazyload class="img-fluid" src="/assets/img/result/X (2023-24).jpg"
                                        alt="X 2023-24">
                                </a>
                            </div>
                        </div>
                        <div class="collapse mt-3" id="year22">
                            <div class="row">
                                <a href="/assets/img/result/X (2022-23).jpg" data-lightbox="Result"
                                    attr.data-title="X 2022-23">
                                    <img appLazyload class="img-fluid" src="/assets/img/result/X (2022-23).jpg"
                                        alt="X 2022-23">
                                </a>
                            </div>
                        </div>

                        <div class="collapse mt-3" id="year21">
                            <div class="row">
                                <a href="/assets/img/result/X (2021-22).jpg" data-lightbox="Result"
                                    attr.data-title="X 2021-22">
                                    <img appLazyload class="img-fluid" src="/assets/img/result/X (2021-22).jpg"
                                        alt="X 2021-22">
                                </a>
                            </div>
                        </div>

                        <div class="collapse mt-3" id="year18">
                            <div class="row">
                                <a href="/assets/img/result/X (2018-19).jpg" data-lightbox="Result"
                                    attr.data-title="X 2018-19">
                                    <img appLazyload class="img-fluid" src="/assets/img/result/X (2018-19).jpg"
                                        alt="X 2018-19">
                                </a>
                            </div>
                        </div>

                        <div class="collapse mt-3" id="year16">
                            <div class="row">
                                <a href="/assets/img/result/X (2016-17).jpg" data-lightbox="Result"
                                    attr.data-title="X 2016-17">
                                    <img appLazyload class="img-fluid" src="/assets/img/result/X (2016-17).jpg"
                                        alt="X 2016-17">
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>