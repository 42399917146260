import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-founder',
  templateUrl: './founder.component.html',
  styleUrls: ['./founder.component.scss']
})
export class FounderComponent implements OnInit {
  pdfSrc='/assets/pdf/THE FOUNDER MEMBERS.pdf';
  isLoading=true;
  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }
}
