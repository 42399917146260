import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  activities:any[]=[
    {
      title:'Testing',
      imgUrl:'/assets/img/activity/1.jpg',
      link:'/assets/img/activity/1.jpg'
    },
    {
      title:'Testing 2',
      imgUrl:'/assets/img/activity/2.jpg',
      link:'/assets/img/activity/2.jpg'
    },
    {
      title:'Testing 3',
      imgUrl:'/assets/img/activity/3.jpg',
      link:'/assets/img/activity/3.jpg'
    },
  ];
  isLoading=false;
  customOptions: OwlOptions = {
    margin:10,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  ngOnInit(): void {
  }

}
