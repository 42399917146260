<app-banner></app-banner>

<div id="contact" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h4 class="card-title text-center">Contact Us</h4>
            </div>
        </div>
        <div class="row mt-4 text-center">
            <div class="col-12 col-md-6">
                <img src="/assets/img/showcase/2.jpg" alt="" class="img-fluid rounded">
            </div>
            <div class="col-12 col-md-6">
                <div class="content">
                    <h3>Aggarsain Public School</h3>
                    <h5><b>ADDRESS :- SECTOR-13, URBAN ESTATE, KURUKSHETRA-136118 HARYANA (INDIA)</b></h5>
                    <!-- <p><b></b></p> -->
                    <p>Contact No.: +91-1744-220910</p>
                    <a href="mailto: aps@aggarsainpublicschool.ac.in">
                        <p><i>E-mail ID: aps@aggarsainpublicschool.ac.in</i></p>
                    </a>
                    <a href="http://aggarsainpublicschool.ac.in/" target="_blank">
                        <p><i>Website: aggarsainpublicschool.ac.in</i></p>
                    </a>
                    <div class=" d-flex justify-content-center align-items-center h-100">
                        <div class="icons me-5">
                            <a href="https://youtube.com/@aggarsainpublicschoolkuruk8041?si=9_2hivEKo7hISUGI"
                                target="_blank" class="yt"><i class="fab fa-youtube"></i></a>
                        </div>
                        <div class="icons me-5">
                            <a href="https://www.instagram.com/apskurukshetra?igsh=eDI0aG40dWI5dnVs" target="_blank"
                                class="ig"><i class="fab fa-instagram" style="left: 9px;"></i></a>
                        </div>
                        <div class="icons"><a href="https://www.facebook.com/share/RMw9rrgqELTnFfHw/?mibextid=qi2Omg"
                                target="_blank" class="fb"><i class="fab fa-facebook" style="left: 7px;"></i></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>