<div class="container py-5">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
            <div class="card mx-auto news">
                <div class="card-header">
                    LATEST NEWS
                </div>
                <ul class="list-group list-group-flush">
                    <marquee behavior="scroll" direction="up" onmouseover="this.stop();" onmouseout="this.start();">
                        <li class="list-group-item" *ngFor="let item of news">
                            <h6 class="mb-0">
                                <ng-container *ngIf="item.link">
                                  <a [href]="item.link" target="_blank">{{ item.title }}</a>
                                </ng-container>
                                <ng-container *ngIf="!item.link">
                                  {{ item.title }}
                                </ng-container>
                              </h6>
                            <small>{{item.date}}</small>
                        </li>
                    </marquee>
                </ul>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
            <div class="card mx-auto facebook">
                <div class="card-header">
                    FORTHCOMING EVENTS 
                </div>
                <ul class="list-group list-group-flush">
                    <marquee behavior="scroll" direction="up" onmouseover="this.stop();" onmouseout="this.start();"
                        scrolldelay="200">
                        <li class="list-group-item" *ngFor="let item of announcements;"
                            >
                            <h6 class="mb-0">
                                <ng-container *ngIf="item.imgUrl">
                                    <a [href]="item.imgUrl" target="_blank">{{ item.title }}</a>
                                  </ng-container>
                                  <ng-container *ngIf="!item.imgUrl">
                                    {{ item.title }}
                                  </ng-container>
                            </h6>
                            <small>{{item.date}}</small>
                        </li>
                    </marquee>
                </ul>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
            <div class="card mx-auto announcement">
                <div class="card-header">
                    PUBLICATIONS
                </div>
                <ul class="list-group list-group-flush">
                    <marquee behavior="scroll" direction="up" onmouseover="this.stop();" onmouseout="this.start();"
                        scrolldelay="200">
                        <li class="list-group-item" *ngFor="let item of publication;"
                            [routerLink]="['/publication',item.$key]">
                            <h6 class="mb-0">{{item.title}}</h6>
                            <small>{{item.date}}</small>
                        </li>
                        <!-- <li class="list-group-item" *ngFor="let item of announcements;"
                            [routerLink]="['/announcements',item.$key]">
                            <h6 class="mb-0">{{item.title}}</h6>
                            <small>{{item.date}}</small>
                        </li> -->
                    </marquee>
                </ul>
            </div>
        </div>

    </div>
</div>
