<app-showcase></app-showcase>
<app-about-us></app-about-us>
<app-news></app-news>
<app-facility></app-facility>
<app-achievements></app-achievements>
<app-activities></app-activities>
<app-testimonial></app-testimonial>

<div id="logo-bar" style="top: 90% !important;">
    <a class="btn btn-lg" href="https://data.odpay.in/odforms1/form/AggarsainPublicSchoolAdmissionQuery/formperma/NzFdErrx3t8WMinJx43Lkk2kBZox7r9W6C7GwBSmSdg" target="_blank">Enquiry</a>
</div>
<!-- <div id="logo-bar" style="top: 95% !important;">
    <a class="btn btn-lg" href="https://data.odpay.in/odforms/form/AggarsainPublicSchoolAdmissionQuery/formperma/lIV9jWT-SpGL6YBfMmyA-_XGdZVDVGXrrmycmbwBAO8" target="_blank">Registration</a>
</div> -->

<div class="modal" tabindex="-1" id="myModal" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
                <div id="popupCarousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let img of popups, let i=index"
                            [ngClass]="(i == 0 ) ? 'active' : ''">
                            <img [src]="img" class="d-block w-100">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#popupCarousel" role="button" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#popupCarousel" role="button" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>