import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { HomeComponent } from './view/home/home.component';
import { ShowcaseComponent } from './view/home/showcase/showcase.component';
import { NewsComponent } from './view/home/news/news.component';
import { ActivitiesComponent } from './view/home/activities/activities.component';
import { AchievementsComponent } from './view/home/achievements/achievements.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutUsComponent } from './view/home/about-us/about-us.component';
import { TestimonialComponent } from './view/home/testimonial/testimonial.component';
import { FacilityComponent } from './view/home/facility/facility.component';
import { MapComponent } from './view/footer/map/map.component';
import { AboutModule } from './modules/about/about.module';

import { SharedModule } from './modules/shared/shared.module';
import { AcademicModule } from './modules/academic/academic.module';
import { AdmissionModule } from './modules/admission/admission.module';
import { FeepaymentModule } from './modules/feepayment/feepayment.module';
import { VacanciesModule } from './modules/vacancies/vacancies.module';
import { MpdModule } from './modules/mpd/mpd.module';
import { ActivitiesModule } from './modules/activities/activities.module';
import { ContactModule } from './modules/contact/contact.module';
import { GalleryModule } from './modules/gallery/gallery.module';
import { AngularFireModule } from 'angularfire2';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { FacultyModule } from './modules/faculty/faculty.module';
import { DownloadsModule } from './modules/downloads/downloads.module';
import { AlumniModule } from './modules/alumni/alumni.module';
import { StudentComponent } from './view/student/student.component';
import { AllPublicationModule } from './modules/all-publication/all-publication.module';
import { AllNoticeModule } from './modules/all-notice/all-notice.module';
import { AllNewsModule } from './modules/all-news/all-news.module';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ShowcaseComponent,
    NewsComponent,
    ActivitiesComponent,
    AchievementsComponent,
    AboutUsComponent,
    TestimonialComponent,
    FacilityComponent,
    MapComponent,
    StudentComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    HttpClientModule,
    SharedModule,
    AboutModule,
    AcademicModule,
    AdmissionModule,   
    FeepaymentModule,
    VacanciesModule,
    MpdModule,
    ActivitiesModule,
    ContactModule,
    GalleryModule,
    FacultyModule,
    DownloadsModule,
    AlumniModule,
    AllPublicationModule,
    AllNoticeModule,
    AllNewsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
