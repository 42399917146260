import { Component, OnInit } from '@angular/core';
import ScrollOut from "scroll-out";
import { Subscription } from 'rxjs';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { NewsService } from 'src/app/services/news/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {  
  announcements: any[] = [
    {
      title: 'REGISTRATION OPEN- (2025-26) ',
      date: '06/12/2024',
      imgUrl: '/assets/img/home-admission/3.jpg'
    },
    {
      title: 'CO-CURRICULAR ACTIVITIES - 2024',
      date: '29/11/2024',
      imgUrl: '/assets/pdf/Diary-2024.pdf'
    }
  ];
  publication:any[]=[];
  news:any[]=[];
  sub:Subscription[]=[];

  constructor(
    private publicationService:PublicationService, 
    private newsService:NewsService){}
    
  so: any;
  


  ngOnInit(): void {
    this.publicationService.getPublicationList();
    this.newsService.getNewsList();
    this.sub[0]= this.publicationService.publicationChange.subscribe(publication=>{
      this.publication = publication;
    });
    this.sub[1]= this.newsService.newsChange.subscribe(news=>{
      this.news = news;
    });
  }
  ngAfterContentInit() {
    this.so = ScrollOut({
      onShown: function (el) {
        // remove the class
        el.classList.remove('animated');

        // force reflow
        void el.offsetWidth;

        // re-add the animated cl
        el.classList.add('animated');
      }
    });
    
    
    // this.sub[1]= this.announcementService.announcementChange.subscribe(announcements=>{
    //   this.announcements = announcements;
    // });
  }
  ngOnDestroy() {
    this.so.teardown();
    this.sub.forEach(s=>s.unsubscribe());
  }
}
