import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staffschoolinfra',
  templateUrl: './staffschoolinfra.component.html',
  styleUrls: ['./staffschoolinfra.component.scss']
})
export class StaffschoolinfraComponent implements OnInit {
  pdfSrc='/assets/pdf/STAFFANDSCHOOLINFRASRUCTURE.pdf'
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
