import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mpd',
  templateUrl: './mpd.component.html',
  styleUrls: ['./mpd.component.scss']
})
export class MpdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
