import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feespayment',
  templateUrl: './feespayment.component.html',
  styleUrls: ['./feespayment.component.scss']
})
export class FeespaymentComponent implements OnInit {
  pdfSrc='/assets/pdf/Feespayment.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
