import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {
  pdfSrcAll='/assets/pdf/activities/Funfilled Trip For The Students Of Classes VI to VIII.pdf';

  isLoading=true;
  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }
  
}
