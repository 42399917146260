import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultacademics',
  templateUrl: './resultacademics.component.html',
  styleUrls: ['./resultacademics.component.scss']
})
export class ResultacademicsComponent implements OnInit {
  pdfSrc='/assets/pdf/Listofholidays.pdf'
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
