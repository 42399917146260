import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmissionComponent } from 'src/app/view/admission/admission.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AdmissionsComponent } from 'src/app/view/admission/admissions/admissions.component';
import { FeestructureComponent } from 'src/app/view/admission/feestructure/feestructure.component';
import { UniformComponent } from 'src/app/view/admission/uniform/uniform.component';
import { PdfViewerModule} from 'ng2-pdf-viewer'



@NgModule({
  declarations: [
    AdmissionComponent,
    AdmissionsComponent,
    FeestructureComponent,
    UniformComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:AdmissionComponent, children:[
        {path:'admissions',component:AdmissionsComponent},
        {path:'feestructure',component:FeestructureComponent},
        {path:'uniform',component:UniformComponent},
      ]}
    ])    
  ]
})
export class AdmissionModule { }
