import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MpdComponent } from 'src/app/view/mpd/mpd.component';
import { DocinfoComponent } from 'src/app/view/mpd/docinfo/docinfo.component';
import { GeninfoComponent } from 'src/app/view/mpd/geninfo/geninfo.component';
import { ResultacademicsComponent } from 'src/app/view/mpd/resultacademics/resultacademics.component';
import { StaffschoolinfraComponent } from 'src/app/view/mpd/staffschoolinfra/staffschoolinfra.component';



@NgModule({
  declarations: [
    MpdComponent,
    DocinfoComponent,
    GeninfoComponent,    
    ResultacademicsComponent,
    StaffschoolinfraComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:MpdComponent, children:[
        {path:'docinfo',component:DocinfoComponent},
        {path:'geninfo',component:GeninfoComponent},
        {path:'resultacademics',component:ResultacademicsComponent},
        {path:'staffschoolinfra',component:StaffschoolinfraComponent},
      ]}

    ])

  ]
})
export class MpdModule { }
