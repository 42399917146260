import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.scss']
})
export class AcademicComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
  }

  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 2 || (url.length === 3 && (url[2]=== 'academics' || url[2]=== 'curriculum'|| url[2]=== 'examination' || url[2]=== 'slc' ||  url[2]=== 'result'))){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else if(url.length === 3 && (url[2]=== 'cbseres' ) ){
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }

}
