import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ContactComponent } from 'src/app/view/contact/contact.component';


@NgModule({
  declarations: [
    ContactComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:ContactComponent,children:[
        {path:'contact',component:ContactComponent}
      ]},

    ])
  ]
})
export class ContactModule { }
