import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlumniComponent } from 'src/app/view/alumni/alumni.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    AlumniComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:AlumniComponent, children:[
        {path:'downloads',component:AlumniComponent}
      ]}])
  ]
})
export class AlumniModule { }
