import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {
  publicationList:AngularFireList<any>;
  publication:any[]=[];
  publicationChange = new BehaviorSubject<any[]>(null);
  publicationItemChnage = new BehaviorSubject<any>(null);
  gotItem:boolean=false;
  sub:Subscription;
  constructor(private firebase:AngularFireDatabase) { }
  getPublicationList(){
    if(!this.gotItem){
      this.publicationList=this.firebase.list('publication');  
      this.sub = this.publicationList.snapshotChanges().subscribe(
        list=>{
        this.publication=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.publication= this.publication.reverse();
         this.publicationChange.next(this.publication);
        }
      );
    }
  }
  getPublicationSearchList(id:string){
    if(!this.gotItem){
      this.publicationList=this.firebase.list('publication');  
      this.sub = this.publicationList.snapshotChanges().subscribe(
        list=>{
        this.publication=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.publication= this.publication.reverse();
         let data = this.publication.find(data=>data.$key===id);
          if(data){
            this.publicationItemChnage.next(data);
          }
          else{
            this.publicationItemChnage.next(null);
          }
        }
      );
    }
  }
  
  searchPublication(id:string){
    let data = this.publication.find(data=>data.$key===id);
    if(data){
      this.publicationItemChnage.next(data);
    }
    else{
      this.publicationItemChnage.next(null);
    }
    // for(let data of this.publication){
    //   if(data.$key===id){
        
    //     return data;
    //   }
    // }
  }
  getPublication(){
    return this.publication;
  }
  getRemainingDate(date:string){
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 10 );
   return(new Date(date).getTime() - todayDate.getTime())
  }
}
