<div id="home-activities" class="py-5 bg-light">
    <div class="container px-2 px-lg-5" data-scroll>
        <div class="row">
            <div class="col-12">
                <h2 class="text-center">Testimonials</h2>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-6">
                <div class="card">
                    <iframe width="100%" height="100%"
                        src="https://www.youtube.com/embed/VIZcQrg5ryE?si=1W3W6PLlWxiiGKR-" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <iframe width="100%" height="100%"
                        src="https://www.youtube.com/embed/Kd8xPc8Qx0I?si=5t8QgFKjbOdCjOa2" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>