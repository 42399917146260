<div id="showcase">
    <div id="showcaseCarousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#showcaseCarousel" *ngFor="let img of imgUrl, let i = index" class="{{ (i == 0) ? 'active' : '' }}" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"
          attr.data-bs-slide-to="{{i}}"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let img of imgUrl, let i=index" [ngClass]="(i == 0 ) ? 'active' : ''">
            <img [src]="img" class="d-block w-100">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#showcaseCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</div>