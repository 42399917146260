import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CountService } from 'src/app/services/count/count.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  today = new Date()
  // count=0;
  // sub:Subscription;
  // constructor(private countService:CountService) { }

  // ngOnInit(): void {
  //   this.sub= this.countService.countChange.subscribe(val=>{
  //     this.count=val;
  //   });
  // }
  // ngOnDestroy(): void {
  //     this.sub.unsubscribe();
  // }
  ngOnInit(): void {
    
  }
}
