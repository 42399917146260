import { Component, OnInit } from '@angular/core';

@Component({
  selector:  'app-committee',
  templateUrl:  './committee.component.html',
  styleUrls:  ['./committee.component.scss']
})
export class CommitteeComponent implements OnInit {
  pdfSrc='/assets/pdf/MANAGING COMMITTEE 2024-25.pdf';
  // pdfSrc='/assets/pdf/ManagingCommittee.pdf';
  isLoading=true;
  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }
}
