<app-banner></app-banner>

<div id="gallery" class="py-5">
    <div class="container">
        <div class="row heading text-center mt-3">
            <h4>Gallery</h4>
        </div>
        <div class="row mt-3">
        <div class="col-12 col-md-6 col-xl-4 mb-4" *ngFor="let image of filterImage;let i = index">
            <div class="box" id="{{image.$key}}" (click)="openGalleryList(image.$key,image.caption)">
                <div class="image">
                    <img [src]="image.imgUrl" [alt]="image.caption" class="img-fluid">
                </div>
                <p>{{ image.caption }}</p>
            </div>

            </div>
        </div>
    </div>
</div>