import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { NoticeService } from "../services/notice/notice.service";

@Injectable({
    providedIn:'root'
})
export class NoticeResolverService implements Resolve<any[]>{
    constructor(private noticeService:NoticeService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any[] | Observable<any[]> | Promise<any[]> {
        const notice = this.noticeService.getNotice();
        if(notice.length === 0){
            this.splitUrl();
            
        }else{
            return notice;
        }
    }
    splitUrl(){
        let url=location.pathname.split('/');
        if(url.length===3){
            this.noticeService.getNoticeSearchList(url[2]);
        }
        
      }
}