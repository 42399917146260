import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  newsList:AngularFireList<any>;
  news:any[]=[];
  newsChange = new BehaviorSubject<any[]>(null);
  newsItemChnage = new BehaviorSubject<any>(null);
  gotItem:boolean=false;
  sub:Subscription;
  constructor(private firebase:AngularFireDatabase) { }
  getNewsList(){
    if(!this.gotItem){
      this.newsList=this.firebase.list('news');  
      this.sub = this.newsList.snapshotChanges().subscribe(
        list=>{
        this.news=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.news= this.news.reverse();
         this.newsChange.next(this.news);
        }
      );
    }
  }
  getNewsSearchList(id:string){
    if(!this.gotItem){
      this.newsList=this.firebase.list('news');  
      this.sub = this.newsList.snapshotChanges().subscribe(
        list=>{
        this.news=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.news= this.news.reverse();
         let data = this.news.find(data=>data.$key===id);
          if(data){
            this.newsItemChnage.next(data);
          }
          else{
            this.newsItemChnage.next(null);
          }
        }
      );
    }
  }
  
  searchNews(id:string){
    let data = this.news.find(data=>data.$key===id);
    if(data){
      this.newsItemChnage.next(data);
    }
    else{
      this.newsItemChnage.next(null);
    }
    // for(let data of this.news){
    //   if(data.$key===id){
        
    //     return data;
    //   }
    // }
  }
  getNews(){
    return this.news;
  }
  getRemainingDate(date:string){
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 10 );
   return(new Date(date).getTime() - todayDate.getTime())
  }
}