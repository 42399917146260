<div id="cbseres">
    <div class="container-fluid innerPageMenu">
        <div class="row">
            <a routerLink="/academic"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5> CBSE Result </h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/academic/cbseres/class-12">Class XII</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/academic/cbseres/class-10">Class X</a>
                        </li>
                       
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>