import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  noticeList:AngularFireList<any>;
  notice:any[]=[];
  noticeChange = new BehaviorSubject<any[]>(null);
  noticeItemChnage = new BehaviorSubject<any>(null);
  gotItem:boolean=false;
  sub:Subscription;
  constructor(private firebase:AngularFireDatabase) { }
  getNoticeList(){
    if(!this.gotItem){
      this.noticeList=this.firebase.list('notices');  
      this.sub = this.noticeList.snapshotChanges().subscribe(
        list=>{
        this.notice=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.notice= this.notice.reverse();
         this.noticeChange.next(this.notice);
        }
      );
    }
  }
  getNoticeSearchList(id:string){
    if(!this.gotItem){
      this.noticeList=this.firebase.list('notices');  
      this.sub = this.noticeList.snapshotChanges().subscribe(
        list=>{
        this.notice=  list.map(item=>{ return {
          $key:item.key,
          diffDate:this.getRemainingDate(item.payload.val().date),
          ... item.payload.val()
        }
         })
         this.notice= this.notice.reverse();
         let data = this.notice.find(data=>data.$key===id);
          if(data){
            this.noticeItemChnage.next(data);
          }
          else{
            this.noticeItemChnage.next(null);
          }
        }
      );
    }
  }
  
  searchNotice(id:string){
    let data = this.notice.find(data=>data.$key===id);
    if(data){
      this.noticeItemChnage.next(data);
    }
    else{
      this.noticeItemChnage.next(null);
    }
    // for(let data of this.notice){
    //   if(data.$key===id){
        
    //     return data;
    //   }
    // }
  }
  getNotice(){
    return this.notice;
  }
  getRemainingDate(date:string){
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 10 );
   return(new Date(date).getTime() - todayDate.getTime())
  }
}