<div id="secondary">
    <div class="container-fluid innerPageMenu">
        <div class="row">
            <div class="col-md-3">
                <a routerLink="/activities/secondary"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Secondary 2023</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')">January</a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')">February</a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')">March</a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')">April</a>
                        </li>
                        <li class="five">
                            <a (click)="changeRoute('5')">May</a>
                        </li>
                        <li class="six">
                            <a (click)="changeRoute('6')">June</a>
                        </li>
                        <li class="seven">
                            <a (click)="changeRoute('7')">July</a>
                        </li>
                        <li class="eight">
                            <a (click)="changeRoute('8')">August</a>
                        </li>
                        <li class="nine">
                            <a (click)="changeRoute('9')">September</a>
                        </li>
                        <li class="ten">
                            <a (click)="changeRoute('10')">October</a>
                        </li>
                        <li class="eleven">
                            <a (click)="changeRoute('11')">November</a>
                        </li>
                        <li class="twelve">
                            <a (click)="changeRoute('12')">December </a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <div class="card-body">
                                    <!-- <h5 class="card-title text-center">LESSON PLAN(2021-22)</h5> -->
                                    <pdf-viewer [src]="src" [render-text]="true" [original-size]="false"
                                        style="display: block;" (on-progress)="hideLoader()"
                                        (error)="hideLoader()"></pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>