<app-banner></app-banner>
<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation" *ngIf="showSideMenu">
                <header class="header">
                    <h5>Activities</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/ncc" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i>NCC </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/debate" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Debate & Declamation</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/workshop" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Workshop </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/quiz" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i>Quiz </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/pre-primary" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Pre Primary </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/primary" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Primary </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/secondary" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Secondary </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/schoolband" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Our School Band
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/sports" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Sports </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/camps" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Summer Camps </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/annual" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Annual Day Celebration </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/tours" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Tours & excursions </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/awards" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Awards & Scholarship </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/eco-club" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Eco Club </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/activities/prefectorial" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Prefectorial Board </a>
                    </li>
                </ul>
            </nav>
        </div>

        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>