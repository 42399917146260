import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  @HostListener("click") onClick(){
    this.checkRoute();
  }
  fullWidth=false;
  showSideMenu = true;
  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.splitUrl();
    });
  }

  checkRoute(){
    setTimeout(()=>{
      this.splitUrl();
    })
  }

  splitUrl(){
    let url=location.pathname.split('/');
    // console.log(url);
    if(url.length === 2 || (url.length === 3 && (url[2]=== 'ncc' || url[2]=== 'debate'|| url[2]=== 'quiz' || url[2]=== 'schoolband' || url[2]=== 'result' || url[2]=== 'camps' || url[2]=== 'eco-club' || url[2]=== 'prefectorial'))){
      this.showSideMenu=true;
      this.fullWidth=false;
    }
    else if(url.length === 3 && (url[2]=== 'primary' || url[2]=== 'secondary' || url[2]==='workshop'|| url[2]=== 'sports' || url[2]=== 'annual' || url[2]=== 'pre-primary' ) ){
      this.showSideMenu=false;
      this.fullWidth=true;
    }
  }

}
