<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">SLC Issued</h4>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrcAll"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <!-- <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc2"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div>
                    <div class="card-body text-justify">
                        <pdf-viewer [src]="pdfSrc"
                        [render-text]="true"
                        [original-size]="false"
                        style="display: block;"
                        (on-progress)="hideLoader()" (error)="hideLoader()"></pdf-viewer>
                    </div> -->
                </div>

            </div>
        </div>


    </div>
</div>