import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoticeService } from 'src/app/services/notice/notice.service';

@Component({
  selector: 'app-notice-item',
  templateUrl: './notice-item.component.html',
  styleUrls: ['./notice-item.component.scss']
})
export class NoticeItemComponent implements OnInit {
  link: string = '';
  id:string;
  sub:Subscription[]=[];
  notice:any;
  sanitizedPdfUrl: SafeResourceUrl;
  constructor(private noticeService:NoticeService,
    private route:ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.link='https://aps-frontend.web.app' + location.pathname;
    this.sub[0]=this.route.params.subscribe(
      (params:Params)=>{
        this.id=params['id'];
        this.noticeService.searchNotice(this.id);
      }
    );
    this.sub[1] = this.noticeService.noticeItemChnage.subscribe(notice=>{
      this.notice = notice;
      this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.notice.imgUrl);
    });
  }
}
