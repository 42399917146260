import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase } from 'angularfire2/database';
import { Subscription } from 'rxjs';
import { Gallery } from 'src/app/models/gallery.model';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  imageDetailList: AngularFireList<any>;
  coverList: AngularFireList<any>;
  images: Gallery[] = [];
  sub: Subscription;
  combinedArray: any;
  cover: Gallery[] = [];
  coverImages: Gallery[] = [];
  newImageObject: {};
  newCoverObject: {};
  imageKeys: string[];
  coverKeys: string[];
  key: string[];
  filterImage: Gallery[] = [];
  private gotItem: boolean = false;
  constructor(private firebase: AngularFireDatabase) { }
  getImageDetailList() {
    if (!this.gotItem) {
      this.imageDetailList = this.firebase.list('imageDetails');
      this.gotItem = true;
    }
  }
  getCoverList() {
    return new Promise((resolve, reject) => {
      this.coverList = this.firebase.list('coverImage');
      this.sub = this.coverList.snapshotChanges().subscribe(
        list => {
          this.coverImages = list.map(item => {
            return {
              $key: item.key,
              ...item.payload.val()
            }
          });
          const pos = this.setPosition();
          resolve(pos)
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setCategory(image: Gallery[]) {
    this.images = image;
    this.newImageObject = this.images.reduce(function (acc, obj) {
      let key = obj['caption'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.imageKeys = Object.keys(this.newImageObject);
  }

  setPosition() {
    this.cover = this.coverImages;
    this.cover = this.cover.sort((a, b) => a.position - b.position);
    this.newCoverObject = this.coverImages.reduce(function (acc, obj) {
      let key = obj['caption'];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    this.combinedArray = this.mergeObjects(this.newCoverObject, this.newImageObject);
    this.coverKeys = Object.keys(this.combinedArray);
    return this.coverKeys;
  }


  setFilter(pos:any) {
    for (let i = 0; i < this.coverKeys.length; i++) {
      this.filterImage[i] = this.combinedArray[this.coverKeys[i]][0];
    }
    return this.filterImage;
  }

  getSingleTypeImages(id: string) {
    return this.combinedArray[id];
  }

  mergeObjects(obj1: any, obj2: any): any {
    const mergedObject: any = {};

    // Iterate over keys of obj1
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        // If the key exists in obj2 as well, merge the arrays
        if (obj2.hasOwnProperty(key)) {
          mergedObject[key] = [...obj1[key], ...obj2[key]];
        } else {
          // Otherwise, copy the array from obj1
          mergedObject[key] = [...obj1[key]];
        }
      }
    }

    // Iterate over keys of obj2
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        // If the key doesn't exist in obj1, copy the array from obj2
        if (!obj1.hasOwnProperty(key)) {
          mergedObject[key] = [...obj2[key]];
        }
      }
    }

    return mergedObject;
  }
}
