import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeepaymentComponent } from 'src/app/view/feepayment/feepayment.component';
import { FeespaymentComponent } from 'src/app/view/feepayment/feespayment/feespayment.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    FeepaymentComponent,
    FeespaymentComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:FeepaymentComponent, children:[
        {path:'student',component:FeespaymentComponent}
      ]}

    ])
   
  ]
})
export class FeepaymentModule { }
