import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {
  imgUrl = [
    '/assets/img/showcase/1.jpg', 
    '/assets/img/showcase/2.jpg', 
    // '/assets/img/activities/NCC & SCHOOL BAND.png', 
    // '/assets/img/activities/SPORTS IMAGE.png', 
    '/assets/img/showcase/6.jpg', 
    '/assets/img/showcase/7.jpg', 
    '/assets/img/showcase/8.jpg', 
    '/assets/img/showcase/9.jpg', 
    '/assets/img/showcase/10.jpg', 
    '/assets/img/showcase/11.jpg', 
    '/assets/img/showcase/4.PNG',
    '/assets/img/showcase/12.jpg', 
    '/assets/img/showcase/13.jpg', 
    '/assets/img/showcase/14.jpg', 
    '/assets/img/showcase/15.jpg', 
    '/assets/img/showcase/16.jpg', 
    '/assets/img/showcase/17.jpg', 
    '/assets/img/showcase/18.jpg', 
    '/assets/img/showcase/19.jpg', 
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
