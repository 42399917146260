import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './view/home/home.component';



const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'about', loadChildren:'./modules/about/about.module#AboutModule'},
  {path:'academic', loadChildren:'./modules/academic/academic.module#AcademicModule'},
  {path:'admission',loadChildren:'./modules/admission/admission.module#AdmissionModule'},
  {path:'fees',loadChildren:'./modules/feepayment/feepayment.module#FeepaymentModule'},
  {path:'vacancies', loadChildren:'./modules/vacancies/vacancies.module#VacanciesModule'},
  {path:'mpd',loadChildren:'./modules/mpd/mpd.module#MpdModule'},
  {path:'notices',loadChildren:'./modules/all-notice/all-notice.module#AllNoticeModule'},
  {path:'news',loadChildren:'./modules/all-news/all-news.module#AllNewsModule'},
  {path:'publication',loadChildren:'./modules/all-publication/all-publication.module#AllPublicationModule'},
  {path:'activities',loadChildren:'./modules/activities/activities.module#ActivitiesModule'},
  {path:'contact-us',loadChildren:'./modules/contact/contact.module#ContactModule'},
  {path:'gallery',loadChildren:'./modules/gallery/gallery.module#GalleryModule'},
  {path:'faculty',loadChildren:'./modules/faculty/faculty.module#FacultyModule'},
  {path:'downloads',loadChildren:'./modules/downloads/downloads.module#DownloadsModule'},
  {path:'alumni',loadChildren:'./modules/alumni/alumni.module#AlumniModule'},  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
