import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import { ActivitiesComponent } from 'src/app/view/activities/activities.component';
import { RouterModule } from '@angular/router';
import { NccComponent } from 'src/app/view/activities/ncc/ncc.component';
import { DebadeclComponent } from 'src/app/view/activities/debadecl/debadecl.component';
import { WorkshopComponent } from 'src/app/view/activities/workshop/workshop.component';
import { QuizComponent } from 'src/app/view/activities/quiz/quiz.component';
import { SchoolbandComponent } from 'src/app/view/activities/schoolband/schoolband.component';
import { ResultofcocurricularComponent } from 'src/app/view/activities/resultofcocurricular/resultofcocurricular.component';

import { SportsComponent } from 'src/app/view/activities/sports/sports.component';
import { SummercampsComponent } from 'src/app/view/activities/summercamps/summercamps.component';
import { AnnualdayComponent } from 'src/app/view/activities/annualday/annualday.component';
import { PrePrimaryComponent } from 'src/app/view/activities/pre-primary/pre-primary.component';
import { PrimaryComponent } from 'src/app/view/activities/primary/primary.component';
import { SecondaryComponent } from 'src/app/view/activities/secondary/secondary.component';
import { PTwofourComponent } from 'src/app/view/activities/primary/p-twofour/p-twofour.component';
import { PTwothreeComponent } from 'src/app/view/activities/primary/p-twothree/p-twothree.component';
import { STwofourComponent } from 'src/app/view/activities/secondary/s-twofour/s-twofour.component';
import { STwothreeComponent } from 'src/app/view/activities/secondary/s-twothree/s-twothree.component';
import { ToursComponent } from 'src/app/view/activities/tours/tours.component';
import { AwardsComponent } from 'src/app/view/activities/awards/awards.component';
import { PpTwofourComponent } from 'src/app/view/activities/pre-primary/pp-twofour/pp-twofour.component';
import { PpTwothreeComponent } from 'src/app/view/activities/pre-primary/pp-twothree/pp-twothree.component';
import { EcoClubComponent } from 'src/app/view/activities/eco-club/eco-club.component';
import { PerfectorialComponent } from 'src/app/view/activities/perfectorial/perfectorial.component';



@NgModule({
  declarations: [
    ActivitiesComponent,
    NccComponent,
    DebadeclComponent,
    WorkshopComponent,
    QuizComponent,
    SchoolbandComponent,
    ResultofcocurricularComponent,
    SportsComponent,
    SummercampsComponent,
    AnnualdayComponent,
    PrimaryComponent,
    PrePrimaryComponent,
    SecondaryComponent,
    PTwothreeComponent,
    PTwofourComponent,
    STwothreeComponent,
    STwofourComponent,
    ToursComponent,
    AwardsComponent,
    PpTwofourComponent,
    PpTwothreeComponent,
    EcoClubComponent,
    PerfectorialComponent,

  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:ActivitiesComponent, children:[
        {path:'ncc',component:NccComponent},
        {path:'debate',component:DebadeclComponent},
        {path:'workshop',component:WorkshopComponent},
        {path:'quiz',component:QuizComponent},
        {path:'schoolband',component:SchoolbandComponent},
        {path:'result',component:ResultofcocurricularComponent},
        {path:'sports',component:SportsComponent},
        {path:'camps',component:SummercampsComponent},
        {path:'annual',component:AnnualdayComponent},
        {path:'tours',component:ToursComponent},
        {path:'awards',component:AwardsComponent},
        {path:'eco-club',component:EcoClubComponent},
        {path:'prefectorial',component:PerfectorialComponent},
        {path:'pre-primary',component:PrePrimaryComponent,children:[
          {path:'2024', component:PpTwofourComponent},
          {path:'2023', component:PpTwothreeComponent},
        ]},
        {path:'primary',component:PrimaryComponent,children:[
          {path:'2024', component:PTwofourComponent},
          {path:'2023', component:PTwothreeComponent},
        ]},
        {path:'secondary',component:SecondaryComponent,children:[
          {path:'2024', component:STwofourComponent},
          {path:'2023', component:STwothreeComponent},
        ]},
      ]}
    ])

  ]
})
export class ActivitiesModule { }
