<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Overview</h4>
                    <img src="/assets/img/about/overview.png" alt="" class="img-fluid">
                </div>

            </div>
        </div>


    </div>
</div>