import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  pdfSrcAll='/assets/pdf/activities/QuizAll1.pdf';
  pdfSrc11='/assets/pdf/activities/quiz11.pdf';
  pdfSrc5='/assets/pdf/activities/quiz5.pdf';
  pdfSrc6='/assets/pdf/activities/quiz6.pdf';
  pdfSrc7='/assets/pdf/activities/quiz7.pdf';
  pdfSrc8='/assets/pdf/activities/quiz8.pdf';
  pdfSrc9='/assets/pdf/activities/quiz9.pdf';
  pdfSrc10='/assets/pdf/activities/quiz10.pdf';
  isLoading=true;

  constructor() { }

  ngOnInit(): void {
  }
  hideLoader(){
    this.isLoading=false;
  }

}
