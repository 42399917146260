import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {
  pdfSrc: string[] = [
    '/assets/pdf/employment.pdf',
    '/assets/pdf/leave-application.pdf',
    '/assets/pdf/adresschange.pdf',
    '/assets/pdf/concent.pdf',
  ];
  src: string;
  isLoading = true;

  constructor() { }

  ngOnInit(): void {
    this.changeRoute('1');
  }

  changeRoute(year: string) {
    this.isLoading = true;
    if (year == '1') {
      $('.first').addClass('active');
      $('.second').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[0];
    }
    if (year == '2') {
      $('.second').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[1];
    }
    if (year == '3') {
      $('.third').addClass('active');
      $('.first').removeClass('active');
      $('.second').removeClass('active');
      $('.four').removeClass('active');
      this.src = this.pdfSrc[2];
    }
    if (year == '4') {
      $('.four').addClass('active');
      $('.first').removeClass('active');
      $('.third').removeClass('active');
      $('.second').removeClass('active');
      this.src = this.pdfSrc[3];
    }
  }
  hideLoader() {
    this.isLoading = false;
  }
}
