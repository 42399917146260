import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmissionComponent } from 'src/app/view/admission/admission.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AdmissionsComponent } from 'src/app/view/admission/admissions/admissions.component';
import { FeestructureComponent } from 'src/app/view/admission/feestructure/feestructure.component';
import { UniformComponent } from 'src/app/view/admission/uniform/uniform.component';
import { PdfViewerModule} from 'ng2-pdf-viewer'
import { EntranceComponent } from 'src/app/view/admission/entrance/entrance.component';



@NgModule({
  declarations: [
    AdmissionComponent,
    AdmissionsComponent,
    FeestructureComponent,
    UniformComponent,
    EntranceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:AdmissionComponent, children:[
        {path:'admissions',component:AdmissionsComponent},
        {path:'feestructure',component:FeestructureComponent},
        {path:'uniform',component:UniformComponent},
        {path:'entrance',component:EntranceComponent},
      ]}
    ])    
  ]
})
export class AdmissionModule { }
