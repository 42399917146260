<div id="admission">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">ENTRANCE TEST SYLLABUS</h4>
                    
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3"><img src="/assets/img/examination/1.jpg" class="img-fluid rounded" alt=""></div>
                        <div class="col-12 col-md-6 mb-3"><img src="/assets/img/examination/2.jpg" class="img-fluid rounded" alt=""></div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</div>