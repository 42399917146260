<app-banner></app-banner>
<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3" *ngIf="showSideMenu">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>Academic</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/academics" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Academics</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/examination" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Examination</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/curriculum" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Curriculum</a>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/result" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> Result </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/slc" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> SLC Issued </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/cbseres" (click)="checkRoute()"><i class="fa-solid fa-angle-right"></i> CBSE Result </a>
                    </li>
                </ul>
            </nav>
        </div>

        <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>