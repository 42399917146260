import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadsComponent } from 'src/app/view/downloads/downloads.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    DownloadsComponent,
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    SharedModule,
    RouterModule.forChild([
      {path:'',component:DownloadsComponent, children:[
        {path:'downloads',component:DownloadsComponent}
      ]}])
  ]
})
export class DownloadsModule { }
