import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcademicComponent } from 'src/app/view/academic/academic.component';
import { RouterModule } from '@angular/router';
import { ExaminationComponent } from 'src/app/view/academic/examination/examination.component';
import { AcademicsComponent } from 'src/app/view/academic/academics/academics.component';
import { CurriculumComponent } from 'src/app/view/academic/curriculum/curriculum.component'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from '../shared/shared.module';
import { ResultComponent } from 'src/app/view/academic/result/result.component';
import { SlcComponent } from 'src/app/view/academic/slc/slc.component';
import { CbseresComponent } from 'src/app/view/academic/cbseres/cbseres.component';
import { TenthComponent } from 'src/app/view/academic/cbseres/tenth/tenth.component';
import { TwelveComponent } from 'src/app/view/academic/cbseres/twelve/twelve.component';

@NgModule({
  declarations: [
    AcademicComponent,
    ExaminationComponent,
    AcademicsComponent,
    CurriculumComponent,
    ResultComponent,
    SlcComponent,
    CbseresComponent,
    TenthComponent,
    TwelveComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'',component:AcademicComponent, children:[
        {path:'examination',component:ExaminationComponent},
        {path:'academics', component:AcademicsComponent},
        {path:'curriculum', component:CurriculumComponent},
        {path:'result', component:ResultComponent},
        {path:'slc', component:SlcComponent},
        {path:'cbseres',component:CbseresComponent,children:[
          {path:'class-12', component:TwelveComponent},
          {path:'class-10', component:TenthComponent}
        ]},
      ]}
    ])

  ]
})
export class AcademicModule { }
