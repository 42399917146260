<app-banner></app-banner>
<div id="faculty" class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg inner-card">
                <h4 class="card-title text-center">Faculty</h4>
                <div class="card-body text-justify">
                    <div class="pdf-container">
                        <pdf-viewer [src]="pdfSrc"
                            [render-text]="true"
                            [original-size]="false"
                            style="display: block; width: 70%; height: 50%; margin: 0 auto;">
                        </pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <router-outlet></router-outlet>
    </div>
</div>
