<div id="activities">
    <div class="container-fluid innerPageMenu">
        <div class="row">
            <div class="col-md-3">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5> Downloads </h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li class="first">
                            <a (click)="changeRoute('1')" href="{{ pdfSrc[0] }} " target="_blank" > Employment Form </a>
                        </li>
                        <li class="second">
                            <a (click)="changeRoute('2')" href="{{ pdfSrc[1] }} " target="_blank"> Leave Application Format </a>
                        </li>
                        <li class="third">
                            <a (click)="changeRoute('3')" href="{{ pdfSrc[2] }} " target="_blank"> Application format for change of address/Bus Route </a>
                        </li>
                        <li class="four">
                            <a (click)="changeRoute('4')" href="{{ pdfSrc[3] }} " target="_blank"> Consent Letter Format </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg">
                                <div class="card-body">
                                    <pdf-viewer [src]="src" [render-text]="true" [original-size]="false"
                                        style="display: block;" (on-progress)="hideLoader()"
                                        (error)="hideLoader()"></pdf-viewer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>