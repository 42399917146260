<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <header class="header">
                    <h5>About Us</h5>
                </header>
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/about/overview"><i class="fa-solid fa-angle-right"></i> Overview </a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/mission"><i class="fa-solid fa-angle-right"></i> Our Mission</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/founder"><i class="fa-solid fa-angle-right"></i> Founder Members</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/committee"><i class="fa-solid fa-angle-right"></i> Managing Committee</a>
                    </li>
                </ul>
            </nav>
        </div>

        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>