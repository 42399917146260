<div id="footer">
    <div class="container-fluid pt-4 pb-3 px-md-3 px-lg-5" style="background: var(--primary);">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                <div class="address  d-flex ">
                    <div class="icon me-3">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <p class="mb-1"><span style="font-size: 18px;"><b>AGGARSAIN</b></span><br> PUBLIC SCHOOL, Sector 13, Urban Estate, Kurukshetra(136118) </p>
                </div>
                <!-- <div class="address  d-flex ">
                    <div class="icon me-3">
                        <i class="fas fa-eye"></i>
                    </div>
                    <p>No. of Visitors: {{count}}</p>
                </div> -->
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-xl-3">
                <div class="phone d-flex">
                    <div class="icon me-3">
                        <i class="fas fa-phone"></i>
                    </div>
                    <a href="tel:91-1744-220910">91-1744-220910</a>
                </div>
                <div class="mail d-flex mt-2">
                    <div class="icon me-3 ">
                        <i class="fas fa-at"></i>
                    </div>
                    <a href="mailto:aps@aggarsainpublicschool.ac.in">aps@aggarsainpublicschool.ac.in</a>
                </div>
                <div class="mail d-flex mt-2">
                    <div class="icon me-3 ">
                        <i class="fas fa-globe"></i>
                    </div>
                    <a href="http://aggarsainpublicschool.ac.in/" target="_blank">aggarsainpublicschool.ac.in</a>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-xl-4 d-flex">
                <div class="watch me-3">
                    <img src="/assets/json/clock.gif" alt="">
                </div>
                <div class="data">
                    <p class="mb-0">School Timings</p>
                    <p class="mb-0">Monday - Saturday 09:00 AM - 03:10 PM</p>
                    <p>{{today | date:'fullDate'}}</p>
                </div>
            </div>
            <div class="col-xl-2 d-none d-xl-block">
                <app-map></app-map>

            </div>
        </div>
    </div>
        <!-- <hr class="opacity-100 bg-white"> -->
        <div class="container-fluid pt-4 pb-3 px-md-3 px-lg-5">
        <div class="row footer-menu">
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>About Us</h3>
                <ul class="ps-0">
                    <li>
                        <a routerLink="/about/overview"> Overview </a>
                    </li>
                    <li>
                        <a routerLink="/about/mission">Our Mission</a>
                    </li>
                    <li>
                        <a routerLink="/about/founder">Founder Members</a>
                    </li>
                    <li>
                        <a routerLink="/about/committee">Managing Committee</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>Activities</h3>
                <ul class="ps-0">
                    <!-- <li><a routerLink="">OASIS Form</a></li> -->
                    <li><a routerLink="/activities/ncc">NCC</a></li>
                    <li><a routerLink="/activities/quiz">Quiz</a></li>
                    <li><a routerLink="/activities/sports">Sports</a></li>
                    <li><a routerLink="/activities/camps">Summer Camps</a></li>
                    <li><a routerLink="/activities/tours"> Tours & excursions</a></li>
                </ul>
            </div>
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>Academic</h3>
                <ul class="ps-0">
                    <li>
                        <a routerLink="/academic/academics">Academics</a>
                    </li>
                    <li>
                        <a routerLink="/academic/examination">Examination</a>
                    </li>
                    <li>
                        <a routerLink="/academic/curriculum">Curriculum</a>
                    </li>
                    <li>
                        <a routerLink="/academic/result">Result</a>
                    </li>
                    <li>
                        <a routerLink="/academic/slc">SLC Issued</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>MANDATORY PUBLIC DISCLOSURE</h3>
                <ul class="ps-0">
                    <li>
                        <a routerLink="/mpd/geninfo">General Information</a>
                    </li>
                    <li>
                        <a routerLink="/mpd/resultacademics">Academics</a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/watch?v=TT6WwjeawXM">Inspection</a>
                    </li>
                    <li>
                        <a href="\assets\pdf\OASIS-FORM.pdf" target="_blank">OASIS Form</a>
                    </li>
                </ul>
            </div>
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>Glimpses</h3>
                <ul class="ps-0">
                    <li>
                        <a routerLink="/gallery">Gallery</a>
                    </li>
                </ul>

            </div>
            <div class="col-6 col-sm-4 col-lg-2">
                <h3>Admission</h3>
                <ul class="ps-0">
                    <li>
                        <a routerLink="/admission/admissions">ADMISSIONS</a>
                    </li>
                    <li>
                        <a routerLink="/admission/feestructure">FEE STRUCTURE</a>
                    </li>
                    <li>
                        <a routerLink="/admission/uniform">School Uniform</a>
                    </li>
                </ul>
            </div>
        </div>
        <hr class="opacity-100 bg-white">
        <div class="row copyright">
            <div class="col-12 col-sm-6">
                <a href="https://okiedokiepay.com" target="_blank">Crafted with ❤️ by Okie Dokie </a> <br>
                <a href="https://okiedokiepay.com" target="_blank">Haryana's No. 1 Campus Automation Partner</a>
            </div>
            <div class="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0">
                <div class="icon social me-3" style="border-color: var(--primary);">
                    <a href="https://www.facebook.com/share/RMw9rrgqELTnFfHw/?mibextid=qi2Omg" target="_blank"><i class="fab fa-facebook align-bottom" style="color: #316FF6;"></i></a>

                </div>
                <div class="icon social me-3"  style="border-color: var(--primary);">
                    <a href="https://www.instagram.com/apskurukshetra?igsh=eDI0aG40dWI5dnVs" target="_blank"><i class="fab fa-instagram align-bottom"
                            style="background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); border-radius: 10px;"></i></a>
                </div>
                <div class="icon social me-3"  style="border-color: var(--primary);">
                    <a href="https://youtube.com/@aggarsainpublicschoolkuruk8041?si=9_2hivEKo7hISUGI" target="_blank"><i class="fab fa-youtube align-bottom"
                            style="color: #FF0000;background:#fff;border-radius: 100%;"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>