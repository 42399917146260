<div id="primary">
    <div class="container-fluid innerPageMenu">
        <div class="row">
            <a routerLink="/activities" *ngIf="showSideMenu"> <i class="fas fa-long-arrow-alt-left"></i> Go Back</a>
            <div class="col-md-3" *ngIf="showSideMenu">
                <nav id="secondary-navigation">
                    <!-- Header -->
                    <header class="header">
                        <h5>Primary</h5>
                    </header>
                    <!-- /Header -->
                    <ul>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/primary/2024" (click)="checkRoute()">2024</a>
                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/activities/primary/2023" (click)="checkRoute()">2023</a>
                        </li>
                       
                    </ul>
                </nav>
            </div>
            <div [ngClass]="fullWidth ? 'col-md-12' : 'col-md-9'">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>